<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Brand</h1>
          <v-form ref="investmentForm">
            <div class="imageBox">
              <v-label>Brand Logo</v-label>
              <img
                v-if="imageFileUrl"
                :src="imageFileUrl"
                alt=""
                @click="onImagePick"
              />

              <v-file-input
                class="mt-1"
                v-if="imageFileUrl == undefined"
                v-model="imageFile"
                outlined
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                @change="onChangeImage"
                placeholder="Brand Image"
              ></v-file-input>
            </div>
              <label for=""><b>Brand Name</b></label>
            <v-text-field
              v-model="brandName"
              outlined
              placeholder="Brand Name"
              class="mt-2"
            ></v-text-field>

            <label for=""><b>Category</b></label>
            <v-select
              placeholder="Category Name"
              v-model="category"
              :items="categories"
              item-text="name"
              item-value="name"
              outlined
              class="mt-2"
            ></v-select>

            <v-btn @click="validate" v-if="!loading" large>Add Brand</v-btn>
            <v-btn v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddBrand",
  data: () => ({
    myCroppa: {},
    brandName: undefined,
    imageFileUrl: undefined,
    imageFile: undefined,
    loading: false,
    category: undefined,
    categories: [],
  }),
  methods: {
    ...mapActions(["postBrand", "getSingleBrand","fetchCategory"]),
    async validate() {
      this.loading = true;
      let formData = new FormData();
      if (this.brandName != undefined) {
        formData.append("brandName", this.brandName);
      }
      if (this.category != undefined) {
        formData.append("category", this.category);
      }
      if (this.imageFile != undefined) {
        formData.append("image", this.imageFile);
      }
      if (this.$route.params.id != undefined) {
        formData.append("_id", this.$route.params.id);
      }

      await this.postBrand(formData);
      if (this.allBrand.status == 200 || this.allBrand.status == 201) {
        this.$router.push("/business/brands");
      }
      this.loading = false;
    },
    onChangeImage() {
      if (this.imageFile != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageFileUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageFile);
      } else {
        this.imageFileUrl = undefined;
      }
    },
    onImagePick() {
      this.imageFileUrl = undefined;
    },
  },
  computed: {
    ...mapGetters(["allBrand","allCategory"]),
  },
  async created() {
    await this.fetchCategory();
    this.categories = this.allCategory.results;
    if (this.$route.params.id) {
      await this.getSingleBrand(this.$route.params.id);
      this.brandName = this.allBrand.results.brandName;
      this.category = this.allBrand.results.category;
      this.imageFileUrl = this.allBrand.results.image;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  display: flex;
  flex-flow: column;
  img {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>